export default {
    inject: ['current_device'],
    props: {
        navbarItems: Array,
        secondaryItems: Array,
    },
    data() {
        return {
            isExpanded: false,
            companyName: gettext('Veridens AB'),
            closeText: gettext('Close')
        }
    },
    methods: {
        toggleIsExpanded() {
            this.isExpanded = !this.isExpanded

            window.localStorage.setItem('expanded-nav', this.isExpanded);

            if (this.current_device === 'MOBILE') {
                window.localStorage.setItem('expanded-nav', false);
                return
            }
            this.adjustContentWidth()
        },
        adjustContentWidth() {
            let contentContainer = document.querySelector('.main-content-container')
            if (this.isExpanded) {
                contentContainer.style.width = 'calc(100% - 230px)'
                return
            }
            contentContainer.style.width = 'calc(100% - 60px)'
        },
        getClass(itemRoute) {
            if (itemRoute.length < 1) {
                return "n-navbar-item"
            }
            //This will not work if the endpoint is different from the passed route
            if (window.location.pathname.includes(itemRoute)) {
                return "n-navbar-item selected"
            }
            return "n-navbar-item"
        },
        getNavClass() {
            let navClass = 'n-navbar'

            if (this.isExpanded) {

                navClass += ' expanded'
            }
            return navClass
        },
        getPageTitle() {
            const title = document.title
            if (title) {
                return title
            }
            return 'Sermi'
        },
        getNavMobileClass() {
            let navClass = 'n-navbar-mobile'

            if (this.isExpanded) {
                navClass += ' expanded'
                return navClass
            }

            return navClass
        },
        onClickAway() {
            return
            if (this.isExpanded && this.current_device !== 'MOBILE') {
                this.isExpanded = !this.isExpanded
                window.localStorage.setItem('expanded-nav', this.isExpanded);
            }
        }
    },
    created() {
        const expanded = window.localStorage.getItem('expanded-nav');
        this.isExpanded = JSON.parse(expanded)
    },
    mounted() {
        this.adjustContentWidth()
    },
    template: `
        <!--  DESKTOP NAVBAR      -->
        <div v-if="current_device === 'DESKTOP'"  :class="getNavClass()" v-click-away="onClickAway">
            <span>
                <div class="n-navbar-collapse-item"  @click="toggleIsExpanded()">
                     <i :class="isExpanded ? 'fas fa-times' : 'fas fa-bars'"></i>
                     <h4>[[[closeText]]]</h4>
                </div>
                <a v-for="item in navbarItems" :class="getClass(item.route)" :href="item.route">
                    <i :class="item.icon"></i>
                    <h4>[[[item.title]]]</h4>
                </a>
            </span>
            <span>
                <template v-for="item in secondaryItems">
                    <slot v-if="item?.slot === 'profile-add'" name="profile-add"></slot>
                    <slot v-if="item?.slot === 'profile-reset'" name="profile-reset"></slot>
                    <slot v-if="item?.slot === 'profile-io-reset'" name="profile-io-reset"></slot>
                    <slot v-if="item?.slot === 'profile-cab-reset-from-io'" name="profile-cab-reset-from-io"></slot>
                    <slot v-if="item?.slot === 'profile-cab-reset-from-ioe'" name="profile-cab-reset-from-ioe"></slot>
                    <slot v-if="item?.slot === 'profile-cab-reset-from-inspector'" name="profile-cab-reset-from-inspector"></slot>
                    <slot v-if="item?.slot ==='language'"  name="language"></slot>
                    <a v-if="item.route" class="n-navbar-item" :href="item.route">
                        <i :class="item.icon"></i>
                        <h4 >[[[item.title]]]</h4>
                    </a>
                </template>
             <div class="n-navbar-company-name" :class="isExpanded ? ' expanded' : ''">[[[companyName]]]</div>
            </span>
        </div>
        <!--  MOBILE NAVBAR      -->
        <div v-else :class="getNavMobileClass()">
            <div class="n-navbar-collapse-item"  @click="toggleIsExpanded()">
                 <i :class="isExpanded ? 'fas fa-times' : 'fas fa-bars'"></i>
                 <h4 >[[[closeText]]]</h4>
            </div>
            <div class="n-flex justify-content-between align-center">
            <h1 style="color: white; margin: 0;" class="page-title">[[[getPageTitle()]]]</h1>                             
            <slot name="avatar"></slot>
            </div>  

            <div class="n-navbar-mobile-content">
                <span>
                    <a v-for="item in navbarItems" :class="getClass(item.route)" :href="item.route">
                        <i :class="item.icon"></i>
                        <h4 >[[[item.title]]]</h4>
                    </a>
                </span>
                <span>
                    <template v-for="item in secondaryItems">
                        <slot v-if="item?.slot === 'profile-add'" name="profile-add"></slot>
                        <slot v-if="item?.slot === 'profile-reset'" name="profile-reset"></slot>
                        <slot v-if="item?.slot ==='language'"  name="language"></slot>
                        <a v-if="item.route" class="n-navbar-item" :href="item.route">
                            <i :class="item.icon"></i>
                            <h4>[[[item.title]]]</h4>
                        </a>
                    </template>
                </span>
            </div>
        </div>
        `,
}
