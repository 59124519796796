import NTooltip from "../n-tooltip.js";
import NCheckbox from "./n-checkbox.js";

class EVENTS {}

EVENTS.UPDATE_MODEL_VALUE = 'update:modelValue'
EVENTS.SAVE = 'auto-save'

export default {
    inject: ["errors"],
    components: {
        "n-tooltip": NTooltip,
        "n-checkbox": NCheckbox,
    },
    watch: {
        errors(newValues) {
            this.error = NorseComponent.getError(this.name, newValues, this.$el)
            if (this.error) {
                this.makeInvalid(this.error)
                return true
            }
            this.initStyle()
            return false
        }
    },
    emits: [EVENTS.UPDATE_MODEL_VALUE, EVENTS.SAVE],
    props: {
        modelValue: Array,
        optional: Boolean,
        hideTooltip: Boolean,
        id: String,
        name: String,
        label: String,
        initial: Array,
        helpText: String,
        initialError: String, // Error rendered on back-end
        disabled: Boolean,
        hideLabel: Boolean,
        fullWidth: Boolean,
        choices: Array,  // of objects with text and value
        groupChoices: Array,  // of objects with arrays of text and value
        type: {
            type: String,
            default: 'primary'
        }
    },
    data() {
        return {
            error: null,
            labelClass: null,
            optionalText: gettext('Optional'),
            selectedItems: []
        }
    },
    methods: {
        initStyle() {
            this.labelClass = 'field-title'
        },
        makeInvalid(error) {
            this.error = error
            this.selectClass = 'select error'
        },
        getContainerClass() {
            let containerClass = 'input-container multi'
            if (this.fullWidth) {
                containerClass += ' full-width'
            }
            return containerClass
        },
        isItemSelected(item) {
            return this.selectedItems.some(i => item.value === i.value)
        },
        toggleSelection(choice) {
            if (this.isItemSelected(choice)) {
                this.selectedItems = this.selectedItems.filter(i => i.value !== choice.value)
            } else {
                // Add the item if it's not selected
                this.selectedItems.push(choice);
            }
            // Emit the updated value
            this.$emit('update:modelValue', this.selectedItems)
    }
    },
    created() {
        this.initStyle()
        if (this.initialError) {
            this.makeInvalid(this.initialError)
        }
        if (this.initial?.length > 0) {
            this.selectedItems = this.initial
        }
    },
    template: `
    <div :class="getContainerClass()">
         <div class="n-flex gap-m" style="justify-content: space-between">
            <label v-if="!hideLabel" :class="labelClass" :for="id">[[[ label ]]]<em class="n-optional-label" v-if="optional"> -[[[optionalText]]]</em></label>
            <n-tooltip v-if="!hideTooltip" :help-text="helpText" :label="label" >
                <i class="fa-sharp fa-regular fa-circle-info"></i>
            </n-tooltip>
        </div>
        <div v-if="choices?.length > 0">
            <div v-for="choice in choices" class="multi-select-item">
                <input 
                    class="form-check-input" 
                    type="checkbox" 
                    :id="choice.text" 
                    :name="name" 
                    :value="choice.value" 
                    :checked="isItemSelected(choice)"
                    @change="toggleSelection(choice)">
                <label style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;" :for="choice.text">
                    [[[choice.text]]]
                </label>
            </div>
        </div>
        <div v-if="error" class="invalid-field-error">
            [[[ error ]]]
        </div>
    </div>
    `,
}
